
export const accessList = Object.freeze({
        printAccessList: ['9880255099', '7019373040', '8867945166', '8497972017', '9398696476', '9284690848', '7348811932', '8197922890', '9902789979', '8105462873', '7899998920', '8499954532', '9353023203', '9177034317', '9902789970', '8867945176', '9148063695', '8867911577', '9945681618', '8867076006', '8675769656'],
        userData: JSON.parse(localStorage.getItem('userData')),
        userWiseRspAccessList: ['9561134855', '6360645362', '9950978987', '9945681618', "8511765361"],
        bulkDownloadByFileAccessList: ['8374146454', '8867076006'],
        distributorExpiryLimitAccess: ['9980656035', '9353023203', '6360645362', '8867076006', '8011590204', '9945681618'],
        cnUploadAccess: ['9972703102', '9035632135', '9148296038', '7661896980', "9573014470", "8197015660"],
        defaultPaymentAccess: ['9972703102', '9304767936', '9148296038', '7661896980', '6003456007', '8011590204', '8340583835'],
        sessionsEditAccess: ["8011590204", "8374146454"],
        retailerExpiryLimit: ["9945681618"],
        ewayBillAccessList:['8374146454','8011590204','6003456007']
})

export const billDigitalSigns = Object.freeze({
        BLR: "https://dashboards.saveo.in/assets/img/blr_sign_copy.png",
        WHL: "https://dashboards.saveo.in/assets/img/whl_sign_copy.png",
        HYD: "https://dashboards.saveo.in/assets/img/hyd_sign_copy.png",
        COI: "https://dashboards.saveo.in/assets/img/COI_SIGN_COPY.jpg",
        eligibleInvoiceDateFrom: 1703788200000,
        eligibleInvoiceDateForCOI: 1712601000000
})

export const issuesTicketComplaintTypes = Object.freeze({
        "Credit": { "Account Disabled": "Complaint", "Credit Related": "Query", "Outstanding Statemnet": "Request", "Payment Update": "Request" },
        "Customer Support": { "Invoice Copy Request": "Request" },
        "Finance": { "Refund Related": "Request", "GST Not Filed": "Complaint", "Saveo Coins Related": "Request" },
        "Logistics": { "Delivery Related": "Request", "Order Cancellation": "Request", "Order Status": "Query", "Damaged Item Delivered": "Complaint", "Delay in Delivery": "Complaint", "Return Related": "Request" },
        "Marketing": { "Gift Related": "Complaint" },
        "Others": { "No Response": "Query", "Others": "Query", "Test": "Query" },
        "RTO": { "Expiry Returns": "Request" },
        "Store": { "Missing Item": "Complaint", "Packing Issue": "Complaint", "Near Expiry": "Complaint", "Batch Number Change": "Complaint", "Wrong Items": "Complaint" },
        "Supply": { "Discount Related": "Complaint", "Margin Related": "Query", "Products Related": "Query", "PTR Change": "Complaint", "Different MRP Item": "Complaint", "GST Not Billed": "Complaint", "Scheme Issue": "Complaint" },
        "Technology": { "App Related": "Complaint" },
        "Televerification": { "DL Verification": "Request", "New Account Activate": "Request", "New User": "Query", "GST Verification": "Request", "Location Update": "Request" }
})
